import * as React from "react"
import Layout from '../components/Layout'
import HeroImage from '../components/Hero'
import {css} from '@emotion/react'
import AboutComponent from '../components/About'

const AboutPage = () => {
  return(
     <div>
      <Layout title="Sumadhura Folium | Apartments in Whitefield">
        
        <div style={{marginTop:"12vh", textAlign:"center"}}>
          <AboutComponent/>
        </div>
      </Layout>
     </div>
  )
}

export default AboutPage
